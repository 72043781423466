@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,800;0,900;1,500;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.button-82-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: -webkit-filter 250ms;
  transition: filter 250ms;
  transition: filter 250ms, -webkit-filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
  transition:
    -webkit-transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1), -webkit-transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}

.button-82-front {
  display: block;
  position: relative;
  padding: 12px 27px;
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  background: hsl(345deg 100% 47%);
  will-change: transform;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  transition:
    -webkit-transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1), -webkit-transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.25rem;
    padding: 12px 42px;
  }
}

.button-82-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
  transition:
    -webkit-transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5), -webkit-transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-front {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  transition: -webkit-transform 34ms;
  transition: transform 34ms;
  transition: transform 34ms, -webkit-transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  transition:
    -webkit-transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5), -webkit-transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  transition: -webkit-transform 34ms;
  transition: transform 34ms;
  transition: transform 34ms, -webkit-transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}


.button-33:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  -webkit-transform: scale(1.05) rotate(-1deg);
          transform: scale(1.05) rotate(-1deg);
}

.title{
  cursor: pointer;
}

.leapFrog {
  bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  width: 30%;
  margin-top: -15%;
}

::-webkit-scrollbar {
  width: 8px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(228, 171, 171);
}

@media screen and (max-width: 499px) {

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 28px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .button-33 {
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-right: 10px;
    display: none;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    color: white;
  }

  #fontSize:hover {
    color: gray;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
  }

  .allWrap {
    background: rgb(49, 89, 63);
    background: radial-gradient(circle, rgba(49, 89, 63, 1) 0%, rgba(52, 74, 61, 1) 50%, rgba(35, 51, 41, 1) 100%);
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(328deg, rgba(0, 0, 0, 0.3693649334733894) 0%, rgba(0, 0, 0, 0.019224877450980338) 50%, rgba(0, 0, 0, 0.3889727766106442) 100%), url(/static/media/0_1.f455aac3.jpg) no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
    height: 100vh;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .subT {
    font-size: 18px;
    color: rgb(235, 212, 190);
    text-align: center;
    text-shadow: 0 0 10px #000000;
    text-transform: capitalize;
    margin-top: 20vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
  }

  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }


  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title2 {
    margin-top: 20vh;
    z-index: 1;
    justify-content: center;
    width: 80%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #ff1e43;
  }

  .boxWrap2Story {
    padding-top: 7%;
    height: -webkit-max-content;
    height: max-content;
  }

  .benefitsMain {
    padding-bottom: 10%;
    background: url(/static/media/0_2.8eb48c63.jpg) no-repeat center center;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 6%;
    padding-bottom: 8%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain {
    margin-bottom: 15%;
    margin-bottom: 15%;
    background: url(/static/media/0_3.79b7fd12.jpg) no-repeat center center;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 12%;
    padding-bottom: 12%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain2 {
    margin-bottom: 15%;
    margin-bottom: 15%;
    background: url(/static/media/coins.57d0c40d.png) no-repeat center center;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 12%;
    padding-bottom: 12%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2Story2 {
    padding-top: 15%;
    padding-bottom: 15%;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    background-color: #00000077;
    padding: 25px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #06c54f,
        #9dff00,
        #fffb00,
        #02be76,
        #00ffd5,
        #0faf00,
        #12b94a,
        #2e852b,
        #08833b);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .aboutA {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    background-color: #00000098;
    padding: 20px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 30px;
  }


  .aboutA img {
    width: 30%;
    height: 30%;
    margin-bottom: auto;
    margin-top: auto;
  }

  .about img {
    width: 65%;
    margin-right: auto;
    margin-left: auto;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .box {
    margin-top: auto;
    margin-bottom: auto;
    background-color: #00000080;
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .box img {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .boxH {
    font-size: 16px;
    color: white;
    text-align: center;
    margin-top: 15px;
  }

  .boxP {
    font-size: 14px;
    color: white;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .conT {
    font-size: 30px;
    color: white;
    z-index: 1;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .storyCon {
    font-size: 16px;
    width: 100%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .buyCon {
    font-size: 16px;
    width: 100%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .buyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 85%;
    display: flex;
  }

  .facetoface img {
    width: 50%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -15%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

}


@media screen and (min-width: 500px) and (max-width: 767px) {

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 35px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .button-33 {
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-right: 10px;
    display: none;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    color: white;
  }

  #fontSize:hover {
    color: gray;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
  }

  .allWrap {
    background: rgb(49, 89, 63);
    background: radial-gradient(circle, rgba(49, 89, 63, 1) 0%, rgba(52, 74, 61, 1) 50%, rgba(35, 51, 41, 1) 100%);
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(328deg, rgba(0, 0, 0, 0.3693649334733894) 0%, rgba(0, 0, 0, 0.019224877450980338) 50%, rgba(0, 0, 0, 0.3889727766106442) 100%), url(/static/media/0_1.f455aac3.jpg) no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
    height: 100vh;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .subT {
    font-size: 18px;
    color: rgb(235, 212, 190);
    text-align: center;
    text-shadow: 0 0 10px #000000;
    text-transform: capitalize;
    margin-top: 15vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
  }

  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }


  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title2 {
    margin-top: 15vh;
    z-index: 1;
    justify-content: center;
    width: 80%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #ff1e43;
  }

  .boxWrap2Story {
    padding-top: 7%;
    height: -webkit-max-content;
    height: max-content;
  }

  .benefitsMain {
    padding-bottom: 10%;
    background: url(/static/media/0_2.8eb48c63.jpg) no-repeat center center;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 6%;
    padding-bottom: 8%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain {
    margin-bottom: 15%;
    margin-bottom: 15%;
    background: url(/static/media/0_3.79b7fd12.jpg) no-repeat center center;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 12%;
    padding-bottom: 12%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain2 {
    margin-bottom: 15%;
    margin-bottom: 15%;
    background: url(/static/media/coins.57d0c40d.png) no-repeat center center;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 12%;
    padding-bottom: 12%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2Story2 {
    padding-top: 15%;
    padding-bottom: 15%;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    background-color: #00000077;
    padding: 25px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #06c54f,
        #9dff00,
        #fffb00,
        #02be76,
        #00ffd5,
        #0faf00,
        #12b94a,
        #2e852b,
        #08833b);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .aboutA {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    background-color: #00000098;
    padding: 20px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 30px;
  }


  .aboutA img {
    width: 30%;
    height: 30%;
    margin-bottom: auto;
    margin-top: auto;
  }

  .about img {
    width: 65%;
    margin-right: auto;
    margin-left: auto;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .box {
    margin-top: auto;
    margin-bottom: auto;
    background-color: #00000080;
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .box img {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .boxH {
    font-size: 16px;
    color: white;
    text-align: center;
    margin-top: 15px;
  }

  .boxP {
    font-size: 14px;
    color: white;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .conT {
    font-size: 40px;
    color: white;
    z-index: 1;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .storyCon {
    font-size: 16px;
    width: 95%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .buyCon {
    font-size: 16px;
    width: 95%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .buyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icons img {
    width: 30px;
    height: 30px;
     margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 60%;
    display: flex;
  }

  .facetoface img {
    width: 50%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -15%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .button-33 {
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-right: 10px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    color: white;
  }

  #fontSize:hover {
    color: gray;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
  }

  .allWrap {
    background: rgb(49, 89, 63);
    background: radial-gradient(circle, rgba(49, 89, 63, 1) 0%, rgba(52, 74, 61, 1) 50%, rgba(35, 51, 41, 1) 100%);
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(328deg, rgba(0, 0, 0, 0.3693649334733894) 0%, rgba(0, 0, 0, 0.019224877450980338) 50%, rgba(0, 0, 0, 0.3889727766106442) 100%), url(/static/media/0_1.f455aac3.jpg) no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
    height: 100vh;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .subT {
    font-size: 18px;
    color: rgb(235, 212, 190);
    text-align: center;
    text-shadow: 0 0 10px #000000;
    text-transform: capitalize;
    margin-top: 15vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
    z-index: 1;
  }

  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }


  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title2 {
    margin-top: 15vh;
    z-index: 1;
    justify-content: center;
    width: 50%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #ff1e43;
  }

  .boxWrap2Story {
    padding-top: 7%;
    height: -webkit-max-content;
    height: max-content;
  }

  .benefitsMain {
    padding-bottom: 10%;
    background: url(/static/media/0_2.8eb48c63.jpg) no-repeat center center;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 10%;
    padding-bottom: 12%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain {
    margin-bottom: 10%;
    background: url(/static/media/0_3.79b7fd12.jpg) no-repeat center center;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain2 {
    margin-bottom: 10%;
    background: url(/static/media/coins.57d0c40d.png) no-repeat center center;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #06c54f,
        #9dff00,
        #fffb00,
        #02be76,
        #00ffd5,
        #0faf00,
        #12b94a,
        #2e852b,
        #08833b);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .aboutA {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    background-color: #00000098;
    padding: 20px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
  }


  .aboutA img {
    width: 30%;
    height: 30%;
    margin-bottom: auto;
    margin-top: auto;
  }

  .about img {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .box {
    margin-top: auto;
    margin-bottom: auto;
    background-color: #00000080;
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .box img {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .boxH {
    font-size: 16px;
    color: white;
    text-align: center;
    margin-top: 15px;
  }

  .boxP {
    font-size: 14px;
    color: white;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .conT {
    font-size: 65px;
    color: white;
    z-index: 1;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .storyCon {
    font-size: 16px;
    width: 85%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .buyCon {
    font-size: 16px;
    width: 95%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .buyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 60%;
    display: flex;
  }

  .facetoface img {
    width: 50%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -15%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
  }

  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -5px;
  }

  .button-33 {
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-right: 10px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    color: white;
  }

  #fontSize:hover {
    color: gray;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
  }

  .allWrap {
    background: rgb(49, 89, 63);
    background: radial-gradient(circle, rgba(49, 89, 63, 1) 0%, rgba(52, 74, 61, 1) 50%, rgba(35, 51, 41, 1) 100%);
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(328deg, rgba(0, 0, 0, 0.3693649334733894) 0%, rgba(0, 0, 0, 0.019224877450980338) 50%, rgba(0, 0, 0, 0.3889727766106442) 100%), url(/static/media/0_1.f455aac3.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
    height: 100vh;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .subT {
    font-size: 20px;
    color: rgb(235, 212, 190);
    text-align: center;
    text-shadow: 0 0 10px #000000;
    text-transform: capitalize;
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
    z-index: 1;
  }

  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }


  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title2 {
    margin-top: 20vh;
    z-index: 1;
    justify-content: center;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #ff1e43;
  }

  .boxWrap2Story {
    padding-top: 7%;
    height: -webkit-max-content;
    height: max-content;
  }

  .benefitsMain {
    padding-bottom: 10%;
    background: url(/static/media/0_2.8eb48c63.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 3%;
    padding-bottom: 6%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain {
    margin-bottom: 10%;
    background: url(/static/media/0_3.79b7fd12.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain2 {
    margin-bottom: 10%;
    background: url(/static/media/coins.57d0c40d.png) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #06c54f,
        #9dff00,
        #fffb00,
        #02be76,
        #00ffd5,
        #0faf00,
        #12b94a,
        #2e852b,
        #08833b);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .aboutA {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    background-color: #00000098;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
  }


  .aboutA img {
    width: 30%;
    height: 30%;
    margin-bottom: auto;
    margin-top: auto;
  }

  .about img {
    width: 38%;
    margin-right: auto;
    margin-left: auto;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .box {
    margin-top: auto;
    margin-bottom: auto;
    background-color: #00000080;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .box img {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .boxH {
    font-size: 16px;
    color: white;
    text-align: center;
    margin-top: 15px;
  }

  .boxP {
    font-size: 14px;
    color: white;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .conT {
    font-size: 85px;
    color: white;
    z-index: 1;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .storyCon {
    font-size: 16px;
    width: 65%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .buyCon {
    font-size: 16px;
    width: 95%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .buyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .facetoface img {
    width: 50%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -15%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #020529b7;
   }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
   }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
    font-family: 'Montserrat', sans-serif;
  }

  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
  }

  .button-33 {
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 10px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  #fontSize:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
  }

  .allWrap {
    background: rgb(49, 89, 63);
    background: radial-gradient(circle, rgba(49, 89, 63, 1) 0%, rgba(52, 74, 61, 1) 50%, rgba(35, 51, 41, 1) 100%);
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(328deg, rgba(0, 0, 0, 0.3693649334733894) 0%, rgba(0, 0, 0, 0.019224877450980338) 50%, rgba(0, 0, 0, 0.3889727766106442) 100%), url(/static/media/0_1.f455aac3.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
    height: 100vh;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .subT {
    font-size: 25px;
    color: rgb(235, 212, 190);
    text-align: center;
    text-shadow: 0 0 10px #000000;
    text-transform: capitalize;
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
    z-index: 1;
  }

  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }


  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title2 {
    margin-top: 10vh;
    z-index: 1;
    justify-content: center;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #ff1e43;
  }

  .boxWrap2Story {
    padding-top: 7%;
    height: -webkit-max-content;
    height: max-content;
  }

  .benefitsMain {
    padding-bottom: 10%;
    background: url(/static/media/0_2.8eb48c63.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 3%;
    padding-bottom: 6%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain {
    margin-bottom: 10%;
    background: url(/static/media/0_3.79b7fd12.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain2 {
    margin-bottom: 10%;
    background: url(/static/media/coins.57d0c40d.png) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
     width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #06c54f,
        #9dff00,
        #fffb00,
        #02be76,
        #00ffd5,
        #0faf00,
        #12b94a,
        #2e852b,
        #08833b);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .aboutA {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    background-color: #00000098;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
  }


  .aboutA img {
    width: 30%;
    height: 30%;
    margin-bottom: auto;
    margin-top: auto;
  }

  .about img {
    width: 38%;
    margin-right: auto;
    margin-left: auto;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .box {
    margin-top: auto;
    margin-bottom: auto;
    background-color: #00000080;
    border-radius: 50px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .box img {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .boxH {
    font-size: 18px;
    color: white;
    text-align: center;
    margin-top: 15px;
  }

  .boxP {
    font-size: 16px;
    color: white;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .conT {
    font-size: 125px;
    color: white;
    z-index: 1;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .storyCon {
    font-size: 18px;
    width: 65%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .buyCon {
    font-size: 18px;
    width: 95%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .buyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }
}

@media screen and (min-width: 1920px) {

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }


  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 50px;
  }

  .logoUp {
    width: 70px;
    height: 70px;
    margin-top: -10px;
  }

  .button-33 {
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 9px 22px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 24px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 20px;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 28px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  #fontSize:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 25px;
    margin-right: 25px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
  }

  .allWrap {
    background: rgb(49, 89, 63);
    background: radial-gradient(circle, rgba(49, 89, 63, 1) 0%, rgba(52, 74, 61, 1) 50%, rgba(35, 51, 41, 1) 100%);
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(328deg, rgba(0, 0, 0, 0.3693649334733894) 0%, rgba(0, 0, 0, 0.019224877450980338) 50%, rgba(0, 0, 0, 0.3889727766106442) 100%), url(/static/media/0_1.f455aac3.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
    height: 100vh;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .subT {
    font-size: 35px;
    color: rgb(235, 212, 190);
    text-align: center;
    text-shadow: 0 0 10px #000000;
    text-transform: capitalize;
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
    z-index: 1;
  }

  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }


  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title2 {
    margin-top: 20vh;
    z-index: 1;
    justify-content: center;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #ff1e43;
  }

  .boxWrap2Story {
    padding-top: 7%;
    height: -webkit-max-content;
    height: max-content;
  }

  .benefitsMain {
    padding-bottom: 10%;
    background: url(/static/media/0_2.8eb48c63.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 3%;
    padding-bottom: 6%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain {
    margin-bottom: 10%;
    background: url(/static/media/0_3.79b7fd12.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain2 {
    margin-bottom: 10%;
    background: url(/static/media/coins.57d0c40d.png) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 24px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #06c54f,
        #9dff00,
        #fffb00,
        #02be76,
        #00ffd5,
        #0faf00,
        #12b94a,
        #2e852b,
        #08833b);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .aboutA {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    background-color: #00000098;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
  }


  .aboutA img {
    width: 30%;
    height: 30%;
    margin-bottom: auto;
    margin-top: auto;
  }

  .about img {
    width: 38%;
    margin-right: auto;
    margin-left: auto;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .box {
    margin-top: auto;
    margin-bottom: auto;
    background-color: #00000080;
    border-radius: 60px;
    margin-left: 15px;
    margin-right: 15px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .box img {
    width: 70px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .boxH {
    font-size: 26px;
    color: white;
    text-align: center;
    margin-top: 20px;
  }

  .boxP {
    font-size: 23px;
    color: white;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .conT {
    font-size: 175px;
    color: white;
    z-index: 1;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .storyCon {
    font-size: 26px;
    width: 65%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .buyCon {
    font-size: 26px;
    width: 95%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .buyCon p {
    color: white;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icons img {
    width: 45px;
    height: 45px;
    margin-left: 15px;
    margin-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footerImg {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }


  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 65px;
  }

  .logoUp {
    width: 90px;
    height: 90px;
    margin-top: -12px;
  }

  .button-33 {
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 14px 30px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 32px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 30px;
  }


  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 38px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  #fontSize:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 25px;
    margin-right: 25px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
  }

  .allWrap {
    background: rgb(49, 89, 63);
    background: radial-gradient(circle, rgba(49, 89, 63, 1) 0%, rgba(52, 74, 61, 1) 50%, rgba(35, 51, 41, 1) 100%);
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(328deg, rgba(0, 0, 0, 0.3693649334733894) 0%, rgba(0, 0, 0, 0.019224877450980338) 50%, rgba(0, 0, 0, 0.3889727766106442) 100%), url(/static/media/0_1.f455aac3.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
    height: 100vh;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .subT {
    font-size: 47px;
    color: rgb(235, 212, 190);
    text-align: center;
    text-shadow: 0 0 10px #000000;
    text-transform: capitalize;
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
    z-index: 1;
  }

  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }


  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title2 {
    margin-top: 20vh;
    z-index: 1;
    justify-content: center;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #ff1e43;
  }

  .boxWrap2Story {
    padding-top: 7%;
    height: -webkit-max-content;
    height: max-content;
  }

  .benefitsMain {
    padding-bottom: 10%;
    background: url(/static/media/0_2.8eb48c63.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 3%;
    padding-bottom: 6%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain {
    margin-bottom: 10%;
    background: url(/static/media/0_3.79b7fd12.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain2 {
    margin-bottom: 10%;
    background: url(/static/media/coins.57d0c40d.png) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 20px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 32px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #06c54f,
        #9dff00,
        #fffb00,
        #02be76,
        #00ffd5,
        #0faf00,
        #12b94a,
        #2e852b,
        #08833b);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .aboutA {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    background-color: #00000098;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 70px;
  }


  .aboutA img {
    width: 30%;
    height: 30%;
    margin-bottom: auto;
    margin-top: auto;
  }

  .about img {
    width: 38%;
    margin-right: auto;
    margin-left: auto;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .box {
    margin-top: auto;
    margin-bottom: auto;
    background-color: #00000080;
    border-radius: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .box img {
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .boxH {
    font-size: 34px;
    color: white;
    text-align: center;
    margin-top: 30px;
  }

  .boxP {
    font-size: 30px;
    color: white;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .conT {
    font-size: 235px;
    color: white;
    z-index: 1;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 40px;
  }

  .storyCon {
    font-size: 35px;
    width: 65%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .buyCon {
    font-size: 35px;
    width: 95%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .buyCon p {
    color: white;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icons img {
    width: 55px;
    height: 55px;
    margin-left: 20px;
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footerImg {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 25px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }
}

@media screen and (min-width: 3840px) {

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 55px;
    padding-top: 55px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }


  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 95px;
  }

  .logoUp {
    width: 130px;
    height: 130px;
    margin-top: -20px;
  }


  .button-33 {
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 22px 38px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 45px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 50px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 56px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  #fontSize:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 55px;
    margin-right: 55px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
  }

  .allWrap {
    background: rgb(49, 89, 63);
    background: radial-gradient(circle, rgba(49, 89, 63, 1) 0%, rgba(52, 74, 61, 1) 50%, rgba(35, 51, 41, 1) 100%);
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(328deg, rgba(0, 0, 0, 0.3693649334733894) 0%, rgba(0, 0, 0, 0.019224877450980338) 50%, rgba(0, 0, 0, 0.3889727766106442) 100%), url(/static/media/0_1.f455aac3.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
    height: 100vh;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .subT {
    font-size: 70px;
    color: rgb(235, 212, 190);
    text-align: center;
    text-shadow: 0 0 10px #000000;
    text-transform: capitalize;
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
    z-index: 1;
  }

  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }


  .logo {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title2 {
    margin-top: 18vh;
    z-index: 1;
    justify-content: center;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #ff1e43;
  }

  .boxWrap2Story {
    padding-top: 7%;
    height: -webkit-max-content;
    height: max-content;
  }

  .benefitsMain {
    padding-bottom: 10%;
    background: url(/static/media/0_2.8eb48c63.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 3%;
    padding-bottom: 6%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain {
    margin-bottom: 10%;
    background: url(/static/media/0_3.79b7fd12.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .buyMain2 {
    margin-bottom: 10%;
    background: url(/static/media/coins.57d0c40d.png) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    padding-top: 7%;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 300px;
    padding-bottom: 300px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 40px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 45px;
  }

  .button-85:before {
    content: "";
    background: linear-gradient(45deg,
        #06c54f,
        #9dff00,
        #fffb00,
        #02be76,
        #00ffd5,
        #0faf00,
        #12b94a,
        #2e852b,
        #08833b);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing-button-85 20s linear infinite;
            animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @-webkit-keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .aboutA {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    background-color: #00000098;
    padding: 60px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 80px;
  }


  .aboutA img {
    width: 30%;
    height: 30%;
    margin-bottom: auto;
    margin-top: auto;
  }

  .about img {
    width: 38%;
    margin-right: auto;
    margin-left: auto;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .box {
    margin-top: auto;
    margin-bottom: auto;
    background-color: #00000080;
    border-radius: 80px;
    margin-left: 30px;
    margin-right: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .box img {
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .boxH {
    font-size: 55px;
    color: white;
    text-align: center;
    margin-top: 50px;
  }

  .boxP {
    font-size: 45px;
    color: white;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .conT {
    font-size: 335px;
    color: white;
    z-index: 1;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 60px;
  }

  .storyCon {
    font-size: 55px;
    width: 65%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .buyCon {
    font-size: 55px;
    width: 95%;
    color: white;
    margin-right: auto;
    margin-left: auto;
  }

  .buyCon p {
    color: white;
    margin-bottom: 40px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icons img {
    width: 80px;
    height: 80px;
    margin-left: 30px;
    margin-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footerImg {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 15px;
    margin-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 40px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }
}
